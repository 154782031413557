// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body_container__TIWMx {

}

.body_section__lXOXP {
    margin: 30px 50px;
    text-align: left;
}

.body_section__lXOXP>div:first-of-type {
    font-weight: 600;
    font-size: 22px;
}

.body_sectionBody__pqC6x {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 400px;
    margin-top: 20px;
}

.body_sectionImage__fwDCX {
    flex-basis: 50%;
    border: 1px solid #000;
}

.body_sectionText__U-nDc {
    flex-basis: 50%;
    width: 60px;
    align-items: center;
    display: flex;
    justify-content: center;
}

.body_content__JCyy0 {
    padding: 0 60px;
    text-align: left;
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/body/index.module.css"],"names":[],"mappings":"AAAA;;AAEA;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,WAAW;IACX,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,mBAAmB;IACnB,aAAa;IACb,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".container {\n\n}\n\n.section {\n    margin: 30px 50px;\n    text-align: left;\n}\n\n.section>div:first-of-type {\n    font-weight: 600;\n    font-size: 22px;\n}\n\n.sectionBody {\n    display: flex;\n    justify-content: space-between;\n    width: 100%;\n    height: 400px;\n    margin-top: 20px;\n}\n\n.sectionImage {\n    flex-basis: 50%;\n    border: 1px solid #000;\n}\n\n.sectionText {\n    flex-basis: 50%;\n    width: 60px;\n    align-items: center;\n    display: flex;\n    justify-content: center;\n}\n\n.content {\n    padding: 0 60px;\n    text-align: left;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `body_container__TIWMx`,
	"section": `body_section__lXOXP`,
	"sectionBody": `body_sectionBody__pqC6x`,
	"sectionImage": `body_sectionImage__fwDCX`,
	"sectionText": `body_sectionText__U-nDc`,
	"content": `body_content__JCyy0`
};
export default ___CSS_LOADER_EXPORT___;
