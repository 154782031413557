import React from "react";
import styles from './index.module.css';

function Section({data}) {
    return (
        <div className={styles.section}>{data.name}</div>
    )
}

export default function Header() {
    const sections = [
        {name: "Sobre Nosotros"},
        {name: "Carreras"},
        {name: "Vida UFAB"},
        {name: "Admisión"},
    ];
    return (
        <div className={styles.container}>
            <div>Universidad UFAB - Sede Arenales </div>
            <div className={styles.sectionContainer}>
                {sections.map((section, index) => {
                    return (
                        <Section data={section} key={index}/>
                    )
                })}
            </div>
        </div>
    )
}