// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header_container__gRBYt {
    display: flex;
    padding: 30px;
    justify-content: space-between;
    background-color: #E8998D;
    color: var(--white);
    font-weight: 500;
    text-align: left;
}

.header_container__gRBYt>div:first-of-type {
    font-size: 24px;
    font-weight: 600;
    text-align: left;
}

.header_sectionContainer__gyvoX {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    font-weight: 300;
}

.header_section__\\+o6dI {
    margin-left: 20px;
    -webkit-user-select: none;
            user-select: none;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/header/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,aAAa;IACb,8BAA8B;IAC9B,yBAAyB;IACzB,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,iBAAiB;IACjB,yBAAiB;YAAjB,iBAAiB;IACjB,eAAe;AACnB","sourcesContent":[".container {\n    display: flex;\n    padding: 30px;\n    justify-content: space-between;\n    background-color: #E8998D;\n    color: var(--white);\n    font-weight: 500;\n    text-align: left;\n}\n\n.container>div:first-of-type {\n    font-size: 24px;\n    font-weight: 600;\n    text-align: left;\n}\n\n.sectionContainer {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0 20px;\n    font-weight: 300;\n}\n\n.section {\n    margin-left: 20px;\n    user-select: none;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `header_container__gRBYt`,
	"sectionContainer": `header_sectionContainer__gyvoX`,
	"section": `header_section__+o6dI`
};
export default ___CSS_LOADER_EXPORT___;
