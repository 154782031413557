import React from "react";
import Careers from "./careers";
import Header from "../../components/header";
import Alumnai from "./alumnai";
import Footer from "./footer";
import Body from "./body";

export default function Home() {
    return (
        <div>
            <Header/>
            <Body/>
            <Alumnai/>
            <Careers/>
            <Footer/>
        </div>
    )
}