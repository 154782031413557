import React from "react";
import styles from './index.module.css';

const lorem = "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum  gravida, leo ac semper lobortis, augue dui scelerisque odio, id ornare  ligula arcu tempor enim. Suspendisse potenti. Suspendisse urna orci,  viverra vitae sagittis ac, convallis ac felis. ";

function SectionTop() {
    return (
        <div className={styles.section}>
            <div>Ingresa a la mejor universidad para doteros</div>
            <div className={styles.sectionBody}>
                <div className={styles.sectionImage} style={{backgroundColor: '#CCC'}}>

                </div>
                <div className={styles.sectionText}>
                    <div className={styles.content}>{lorem}</div>
                </div>
            </div>
        </div>
    )
}

function SectionBottom() {
    return (
        <div className={styles.section}>
            <div style={{textAlign: "right"}}>Comparte aulas con los mejores autistas del planeta</div>
            <div className={styles.sectionBody}>
                <div className={styles.sectionText}>
                    <div className={styles.content}>{lorem}</div>
                </div>
                <div className={styles.sectionImage} style={{backgroundColor: '#CCC'}}>

                </div>
            </div>
        </div>
    )
}

export default function Body() {
    return (
        <div className={styles.container}>
            <SectionTop/>
            <SectionBottom/>
        </div>
    )
}