// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.alumnai_container__mk-JJ>div:first-of-type {
    font-weight: 600;
    text-align: left;
    margin: 20px 50px;
}

.alumnai_alumnaiContainer__YrwzI {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    margin: 0 20px;
}

.alumnai_alumnus__N7Spq {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: space-between;
}

.alumnai_alumnus__N7Spq>div:last-of-type {
    font-weight: 600;
}

.alumnai_imageContainer__sMw6P {
    width: 110px;
    margin-bottom: 6px;
}

.alumnai_imageContainer__sMw6P img {
    border-radius: 50%;
    object-fit: cover;
    width: 100%;
    height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/alumnai/index.module.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,6BAA6B;IAC7B,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,eAAe;IACf,8BAA8B;AAClC;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,WAAW;IACX,YAAY;AAChB","sourcesContent":[".container>div:first-of-type {\n    font-weight: 600;\n    text-align: left;\n    margin: 20px 50px;\n}\n\n.alumnaiContainer {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: space-around;\n    margin: 0 20px;\n}\n\n.alumnus {\n    display: flex;\n    align-items: center;\n    flex-direction: column;\n    flex-wrap: wrap;\n    justify-content: space-between;\n}\n\n.alumnus>div:last-of-type {\n    font-weight: 600;\n}\n\n.imageContainer {\n    width: 110px;\n    margin-bottom: 6px;\n}\n\n.imageContainer img {\n    border-radius: 50%;\n    object-fit: cover;\n    width: 100%;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `alumnai_container__mk-JJ`,
	"alumnaiContainer": `alumnai_alumnaiContainer__YrwzI`,
	"alumnus": `alumnai_alumnus__N7Spq`,
	"imageContainer": `alumnai_imageContainer__sMw6P`
};
export default ___CSS_LOADER_EXPORT___;
