import React from "react";
import styles from './index.module.css';

function Alumnus({data}) {
    return (
        <div className={styles.alumnus}>
            <div className={styles.imageContainer}>
                <img src="https://via.placeholder.com/150" alt="alumnus"/>
            </div>
            <div>{data.name}</div>
            <div>{data.career}</div>
        </div>
    )
}

export default function Alumnai() {
    const alumnai = [
        {name: "Fabian del Castillo", career: "Ingeniero Delcatrónico"},
        {name: "Fabian del Castillo", career: "Trólogo Profesional"},
        {name: "Fabian del Castillo", career: "Metodológico del Método"},
        {name: "Fabian del Castillo", career: "Químico de caca"},

    ];
    return (
        <div className={styles.container}>
            <div>Nuestros egresados:</div>
            <div className={styles.alumnaiContainer}>
                {alumnai.map((alumnus, index) => {
                    return (
                        <Alumnus data={alumnus} key={index}/>
                    );
                })}
            </div>
        </div>
    )
}