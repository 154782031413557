import React from "react";
import {carreras} from "../../../data";
import styles from './index.module.css';

function Section({data}) {
    return (
        <div className={styles.section}>
            <div>{data.section}</div>
            <div className={styles.careerContainer}>
                {data.careers.map((career, index) => {
                    return (
                        <div key={index} className={styles.career}>
                            <div>
                                <img src="https://via.placeholder.com/150" alt="career"/>
                            </div>
                            <div>{career.name}</div>
                        </div>
                    )
                })}
            </div>
        </div>
    );
}

export default function Careers() {
    return (
        <div>
            {carreras.map((section, index) => {
                return (
                    <Section data={section} key={index}/>
                )
            })}
        </div>
    );
}