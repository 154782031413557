import React from "react";
import { Routes, Route} from "react-router-dom";
import Home from "./screens/home/home";

export default function RouteContainer() {
    return (
        <Routes>
            <Route path="/" element={<Home />} />
        </Routes>
    )
}