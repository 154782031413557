import React from "react";
import styles from './index.module.css';

function Section({data}) {
    return (
        <div className={styles.section}>
            <div>{data.name}</div>
            {data.items.map((item, index) => (
                <div key={index}>{item}</div>
            ))}
        </div>
    )
}

export default function Footer() {
    const sections = [
        {name: "Sobre Nosotros", items: ["Contacto", "Libro de reclamaciones", "Escala de pensiones", "Empleos", "Libro de reclamaciones"]},
        {name: "Campus", items: ["Campus SJL", "Campus Arenales", "Campus Valle Hermoso", "Campus Barranco", "Campus Discord"]},
    ];

    return (
        <div className={styles.container}>
            <div className={styles.sectionContainer}>
                {sections.map((section, index) => (
                    <Section key={index} data={section}/>
                ))}
            </div>
        </div>
    )
}