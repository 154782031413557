const carreras = [
    {
        section: "Arte y Diseño",
        careers: [
            { name: "OSU! Profesional" },
            { name: "PixelArt" },
            { name: "Escultura de Caca" },
            { name: "Música para Youtube" },
            { name: "Creación y Producción Escénica Pornográfica" },
            { name: "Danza Kuduro" },
            { name: "Pintura de Paredes" }
        ]
    },
    {
        section: "Ciencia e Ingenería",
        careers: [
            { name: "Química Mazamorrera" },
            { name: "Frenología" },
            { name: "Técnico de Lobotomías" },
            { name: "NFT Trading" },
            { name: "Piloto de Drones" },
            { name: "Técnico en Pruebas de Paternidad" },
            { name: "Sexología de Perros" },
            { name: "Ciencias de la Trología" },
            { name: "Mineralización" },
            { name: "Estadística Racial" },
            { name: "Ingeniería de Niñas" },
            { name: "Relaciones Interraciales" },
            { name: "Uróloga de Caballos" }
        ]
    },
    {
        section: "Ciencias Empresariales",
        careers: [
            { name: "Administración de Venecas" },
            { name: "Trading de Arcanas" },
            { name: "Moderación de Discord" },
            { name: "Contabilidad de Monedas" },
            { name: "Gestión de Imbeciles" },
            { name: "Publicidad de Kinesiólogas" },
            { name: "Marketing de OnlyFans" }
        ]
    },
    {
        section: "Estudios Interdisciplinarios",
        careers: [
            { name: "Gastronomía de Raspadillas" },
            { name: "Repostería Metafísica" },
            { name: "Hervidor de Choclos Profesional" },
            { name: "Turismo de Conos" },
            { name: "Modelaje de Pies" }
        ]
    },
];

export {
    carreras
};