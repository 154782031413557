// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
    --brown: #A1683A;
    --white: #FBF7F4;
    --light-pink: #EED2CC;
    --pink: #E8998D;
    --blue: #6C9A8B;
}

.App {
    text-align: center;
    height: 100%;
}

html,
body,
#root {
    margin: 0;
    height: 100%;
    background-color: var(--light-pink);
    animation: backgroundAnimation 20s linear infinite;
    font-family: "Arial", SansSerif;
}`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;IACf,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,YAAY;AAChB;;AAEA;;;IAGI,SAAS;IACT,YAAY;IACZ,mCAAmC;IACnC,kDAAkD;IAClD,+BAA+B;AACnC","sourcesContent":[":root {\n    --brown: #A1683A;\n    --white: #FBF7F4;\n    --light-pink: #EED2CC;\n    --pink: #E8998D;\n    --blue: #6C9A8B;\n}\n\n.App {\n    text-align: center;\n    height: 100%;\n}\n\nhtml,\nbody,\n#root {\n    margin: 0;\n    height: 100%;\n    background-color: var(--light-pink);\n    animation: backgroundAnimation 20s linear infinite;\n    font-family: \"Arial\", SansSerif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
