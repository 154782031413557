// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.careers_section__46RPx {
    margin: 20px 50px;
    -webkit-user-select: none;
            user-select: none;
}

.careers_section__46RPx>div:first-of-type {
    font-size: 24px;
    font-weight: 600;
    border-left: 5px solid var(--brown);
    border-bottom: 1px solid var(--brown);
    border-radius: 4px 0 0 4px;
    text-align: left;
    padding: 10px 20px;
}

.careers_careerContainer__VCUdF {
    display: flex;
    flex-wrap: wrap;
    justify-content: start;
    margin-top: 20px;
}

.careers_career__aO85M {
    width: 20%;
    margin-right: calc(20% / 3);
    background-color: #6C9A8B;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    height: 110px;
    align-items: center;
    display: flex;
    overflow: hidden;
}

.careers_career__aO85M:hover {
    background-color: #5C8A7B;
}

.careers_career__aO85M img {
    object-fit: contain;
    width: 100%;
}

.careers_career__aO85M>div {
    width: 50%;
    text-align: center;
    font-size: 16px;
    font-weight: 600;
}

.careers_career__aO85M:nth-child(4n) {
    margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/careers/index.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mCAAmC;IACnC,qCAAqC;IACrC,0BAA0B;IAC1B,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,eAAe;IACf,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,2BAA2B;IAC3B,yBAAyB;IACzB,mBAAmB;IACnB,uCAAuC;IACvC,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,mBAAmB;IACnB,WAAW;AACf;;AAEA;IACI,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".section {\n    margin: 20px 50px;\n    user-select: none;\n}\n\n.section>div:first-of-type {\n    font-size: 24px;\n    font-weight: 600;\n    border-left: 5px solid var(--brown);\n    border-bottom: 1px solid var(--brown);\n    border-radius: 4px 0 0 4px;\n    text-align: left;\n    padding: 10px 20px;\n}\n\n.careerContainer {\n    display: flex;\n    flex-wrap: wrap;\n    justify-content: start;\n    margin-top: 20px;\n}\n\n.career {\n    width: 20%;\n    margin-right: calc(20% / 3);\n    background-color: #6C9A8B;\n    margin-bottom: 20px;\n    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);\n    border-radius: 4px;\n    height: 110px;\n    align-items: center;\n    display: flex;\n    overflow: hidden;\n}\n\n.career:hover {\n    background-color: #5C8A7B;\n}\n\n.career img {\n    object-fit: contain;\n    width: 100%;\n}\n\n.career>div {\n    width: 50%;\n    text-align: center;\n    font-size: 16px;\n    font-weight: 600;\n}\n\n.career:nth-child(4n) {\n    margin-right: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"section": `careers_section__46RPx`,
	"careerContainer": `careers_careerContainer__VCUdF`,
	"career": `careers_career__aO85M`
};
export default ___CSS_LOADER_EXPORT___;
