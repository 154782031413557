// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.footer_container__Rj0QW {
    background-color: var(--blue);
    color: var(--white);
    padding: 30px 50px;
}

.footer_sectionContainer__Tch7J {
    display: flex;
    justify-content: start;
}

.footer_section__Gg-M\\+ {
    text-align: left;
    margin-right: 50px;
    -webkit-user-select: none;
            user-select: none;
}

.footer_section__Gg-M\\+>div:first-of-type {
    margin-bottom: 10px;
    font-weight: 600;
    cursor: default;
}

.footer_section__Gg-M\\+>div {
    margin-bottom: 8px;
    cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/screens/home/footer/index.module.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;IAClB,yBAAiB;YAAjB,iBAAiB;AACrB;;AAEA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB","sourcesContent":[".container {\n    background-color: var(--blue);\n    color: var(--white);\n    padding: 30px 50px;\n}\n\n.sectionContainer {\n    display: flex;\n    justify-content: start;\n}\n\n.section {\n    text-align: left;\n    margin-right: 50px;\n    user-select: none;\n}\n\n.section>div:first-of-type {\n    margin-bottom: 10px;\n    font-weight: 600;\n    cursor: default;\n}\n\n.section>div {\n    margin-bottom: 8px;\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `footer_container__Rj0QW`,
	"sectionContainer": `footer_sectionContainer__Tch7J`,
	"section": `footer_section__Gg-M+`
};
export default ___CSS_LOADER_EXPORT___;
